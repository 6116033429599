import { calculateLoanDetails } from "../personalCalculator/XIRR";

const progressHome = document.querySelectorAll('.home-calc__range-input');
const radiosHome = document.querySelectorAll('.home-calc__nationality-input');
const circleHome = document.querySelector('.home-calc__circle') as HTMLElement;
const homeSingularTenureElm = document.querySelector('.home-calc__tenor-singular') as HTMLInputElement;
const homePluralTenureElm = document.querySelector('.home-calc__tenor-plural') as HTMLInputElement;
const flatRateSaudiInput = document.getElementById("home-calc--flat-rate-saudi") as HTMLInputElement;
const flatRateNonSaudiInput = document.getElementById("home-calc--flat-rate-non-saudi") as HTMLInputElement;
const timeSeedInput = document.querySelector("#timeSeed") as HTMLInputElement;
const isArabic = document.querySelector("html").getAttribute("lang") === "ar";

const flatRateSaudi = flatRateSaudiInput?.value ? +(flatRateSaudiInput).value / 100 : 0.11;
const flatRateNonSaudi = flatRateNonSaudiInput?.value ? +(flatRateNonSaudiInput).value / 100 : 0.12;
const timeSeed = timeSeedInput ? new Date(+timeSeedInput.value) : new Date();

const salaryRangeInput = document.getElementById('home-calc__salary') as HTMLInputElement;
const salaryTextInput = document.getElementById('home-calc__salary-value') as HTMLInputElement;

const propertyRangeInput = document.getElementById('home-calc__property') as HTMLInputElement;
const propertyTextInput = document.getElementById('home-calc__property-value') as HTMLInputElement;

const setInputWidth = (rangeInput, input) => {
    input.style.width = `${rangeInput.value.length * 10 + 15}px`;
}


function initHome() {
  if (salaryRangeInput) {
    setInputWidth(salaryRangeInput, salaryTextInput);
    salaryRangeInput.addEventListener('change', (e) => {
      setInputWidth(salaryRangeInput, salaryTextInput);
    });
  }

  if (propertyRangeInput) {
    setInputWidth(propertyRangeInput, propertyTextInput);
    propertyRangeInput.addEventListener('change', (e) => {
      setInputWidth(propertyRangeInput, propertyTextInput);
    });
  }
  
  if (progressHome) {
    Array.from(progressHome).forEach((item) => {
      const salaryInput = document.getElementById("home-calc__salary") as HTMLInputElement
      const tenorInput = document.getElementById("home-calc__tenor") as HTMLInputElement
      const propertyInput = document.getElementById("home-calc__property") as HTMLInputElement
      const downPaymentInput = document.getElementById("home-calc__down-payment") as HTMLInputElement

      const salaryDefaultValue = salaryInput.dataset.default
      const tenureDefaultValue = tenorInput.dataset.default
      const propertyDefaultValue = propertyInput.dataset.default
      const downPaymentDefaultValue = downPaymentInput.dataset.default

      salaryTextInput.value = formatPrice(salaryDefaultValue.toString())
      propertyTextInput.value = formatPrice(propertyDefaultValue.toString())

      salaryInput.value = salaryDefaultValue.toString()
      tenorInput.value = tenureDefaultValue.toString()
      propertyInput.value = propertyDefaultValue.toString()
      downPaymentInput.value = downPaymentDefaultValue.toString()

      setHomeInputs(item)
    })
  }

  if (radiosHome) {
    Array.from(radiosHome).forEach(input => {
      input.addEventListener("change", e => {
        setHomeValues()
      })
    })
  }

  if (progressHome) {
    Array.from(progressHome).forEach((item) => {
      const value: number = +(item as HTMLInputElement).value;
      const minValue: number = +(item as HTMLInputElement).min;
      const maxValue: number = +(item as HTMLInputElement).max;
      const type = (item as HTMLInputElement).name

      if (type === "tenor") {
        (item as HTMLInputElement).value = Math.ceil((maxValue / 2)).toString()
        handleHomeTenureChange(item)
      } else {
        (item as HTMLInputElement).value = ((maxValue - minValue) / 2).toString()
      }

      item.addEventListener('input', function () {
        setHomeInputs(item)
      })

      setHomeInputs(item)
    })
  }
}

function handleHomeTenureChange(elm) {
  if (homeSingularTenureElm && homePluralTenureElm) {
    const tenorElm = document.querySelector(".home-calc__tenor-value-label") as HTMLElement

    if (isArabic) tenorElm.innerText = +elm.value === 1 ? homeSingularTenureElm.value : homePluralTenureElm.value
    else {
      const value: number = +(elm as HTMLInputElement).value;
      if (value >= 3 && value <= 10)
        tenorElm.innerText = homePluralTenureElm.value
      else
        tenorElm.innerText = homeSingularTenureElm.value
    }
  }
}

const formatPrice = (value: string) => parseInt(value.replace(/,/g, "")).toLocaleString("en-US")
const parsePrice = (value: string) => value.replace(/,/g, "")

function setHomeInputs(item) {
  const value: number = +(item as HTMLInputElement).value;
  const minValue: number = +(item as HTMLInputElement).min;
  const maxValue: number = +(item as HTMLInputElement).max;
  const type = (item as HTMLInputElement).name

  let percentage = 0

  percentage = ((value - minValue) / (maxValue - minValue)) * 100;

  const valueElement = document.querySelector(`.home-calc__${type}-value`) as HTMLInputElement
  const inputValue = (item as HTMLInputElement).value
  valueElement.value = (type === "salary" || type === "property")
    ? formatPrice(inputValue) : inputValue

  if (type === "tenor") handleHomeTenureChange(item)

  if (document.dir == "rtl") {
    item.style.background = `linear-gradient(to left, #18CDB7 0%, #18CDB7 ${percentage}%, #1B82E9 ${percentage}%, #1B82E9 100%)`
  } else {
    item.style.background = `linear-gradient(to right, #18CDB7 0%, #18CDB7 ${percentage}%, #1B82E9 ${percentage}%, #1B82E9 100%)`
  }

  setHomeValues()

  valueElement.addEventListener("change", e => {
    const inputElement = document.querySelector(`.home-calc__${type}-input`) as HTMLInputElement
    const parsedValue = parsePrice(valueElement.value)
    inputElement.value = parsedValue
    let percentage = 0
    percentage = ((+parsedValue - minValue) / (maxValue - minValue)) * 100;
    if (type === "salary" || type === "property") valueElement.value = formatPrice(parsedValue)

    if (+parsedValue > maxValue) {
      inputElement.value = maxValue.toString()
      valueElement.value = formatPrice(maxValue.toString())
    } else if (+parsedValue < minValue) {
      inputElement.value = minValue.toString()
      valueElement.value = formatPrice(minValue.toString())
    }

    if (document.dir == "rtl") {
      item.style.background = `linear-gradient(to left, #18CDB7 0%, #18CDB7 ${percentage}%, #1B82E9 ${percentage}%, #1B82E9 100%)`
    } else {
      item.style.background = `linear-gradient(to right, #18CDB7 0%, #18CDB7 ${percentage}%, #1B82E9 ${percentage}%, #1B82E9 100%)`
    }

    setHomeValues()
  })
}

function pos_to_neg_home(num) {
  return -Math.abs(num);
}

var RATEHOME = function (nper, pmt, pv, fv = 0, type = 0, guess = 0.1) {
  const epsMax = 1e-10;
  const maxIterations = 2000;

  let rate = guess;
  for (let i = 0; i < maxIterations; i++) {
    const f = Math.pow(1 + rate, nper);
    const y = pv * f + pmt * (1 + rate * type) * (f - 1) / rate + fv;

    if (Math.abs(y) < epsMax) {
      break;
    }

    const dy = nper * pv * Math.pow(1 + rate, nper - 1)
      + pmt * type * (f - 1) / (rate * rate)
      + pmt * (1 + rate * type)
      * (nper * Math.pow(1 + rate, nper - 1) / rate - (f - 1) / (rate * rate));

    rate -= y / dy;
  }

  return rate;
};

function setHomeValues() {
  const TermElement = document.getElementById("home-calc__tenor") as HTMLInputElement
  const Term = +TermElement.value * 12
  const nationalityElement = document.querySelector('.home-calc__nationality-input:checked') as HTMLInputElement
  const Nationality = nationalityElement.value
  const TotalIncomeElement = document.getElementById("home-calc__salary") as HTMLInputElement
  const MonthlyIncome = +TotalIncomeElement.value
  const PropertyValueElement = document.getElementById("home-calc__property") as HTMLInputElement
  const PropertyValue = +PropertyValueElement.value
  const DownPaymentElement = document.getElementById("home-calc__down-payment") as HTMLInputElement
  const DownPayment = +DownPaymentElement.value / 100

  var a6 = 0;
  if (MonthlyIncome >= 25000) {
    a6 = 0.75;
  }
  else if (MonthlyIncome >= 15000) {
    a6 = 0.65;
  }
  else if (MonthlyIncome < 15000) {
    a6 = 0.55;
  }
  var a7 = Term;

  var a8 = Nationality == "Saudi" ? flatRateSaudi : flatRateNonSaudi;
  var a9 = a6 * MonthlyIncome;
  // Total Loan Amount
  var a10 = a9 * a7;
  //finance amount
  var a11 = a10 / (1 + ((a7 * a8 / 12)));
  //management fees
  var a13 = Math.min(a10 * 0.01, 5000);
  var a14 = a13 * 0.15;
  if (a14 > 750) {
    a14 = 750;
  }
  var a15 = a13 + a14;
  var a22 = PropertyValue * DownPayment;
  var a23 = PropertyValue - a22;
  var a24 = a10 * 0.01;
  if (a24 > 5000) {
    a24 = 5000;
  }
  var a25 = Math.min(a24 * 0.15, 750);
  a24 += a25;
  var a26 = Term;

  var a27 = a8 * 100;
  var a28 = Math.abs(a23) * a26 * a27 / 1200;
  var a30 = a23 + a28;
  var a31 = a23 - a24 - a25;
  var a32 = a30 / a26;
  var l6 = RATEHOME(a26, pos_to_neg_home(a32), a31);
  var l7 = (((1 + l6) ** 12) - 1);

  const loanDetails = calculateLoanDetails(a23, a7, a8, a24, timeSeed);

  if (a9 < a32) {
    showError()
  } else {
    removeError()
    const output1 = document.querySelector(".home-calc__payment-number") as HTMLElement
    const output2 = document.querySelector(".home-calc__payment-rate-amount") as HTMLInputElement

    output1.textContent = a32.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // output2.textContent = formatHomeNumber((l7 * 100))
    output2.textContent = formatHomeNumber(loanDetails.apr)
  }
}

function formatHomeNumber(num: number) {
  if (num < 0) num *= -1;

  if (+num % 1 !== 0) {
    return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

function showError() {
  const errorElement = document.querySelector(".home-calc__error-wrapper") as HTMLElement
  errorElement.classList.add("home-calc__error-wrapper--active")
}

function removeError() {
  const errorElement = document.querySelector(".home-calc__error-wrapper") as HTMLElement
  errorElement.classList.remove("home-calc__error-wrapper--active")
}

initHome();