import { calculateLoanDetails, calculatePrincipalAmount } from "./XIRR";

const personalCalc = document.querySelector(".personal-calc") as HTMLDivElement;
const progress = document.querySelectorAll('.personal-calc__range-input') as NodeListOf<HTMLInputElement>;
const radios = document.querySelectorAll('.personal-calc__nationality-input');
const circle = document.querySelector('.personal-calc__circle') as HTMLElement;
const singularTenureElm = document.querySelector('.personal-calc__tenor-singular') as HTMLInputElement;
const pluralTenureElm = document.querySelector('.personal-calc__tenor-plural') as HTMLInputElement;
let managementFee = 0;
let maxThreshold = 0;
const saudiFlatRateInput = personalCalc?.querySelector("#saudiFlatRate") as HTMLInputElement;
const nonSaudiFlatRateInput = personalCalc?.querySelector("#nonSaudiFlatRate") as HTMLInputElement;
const timeSeedInput = personalCalc?.querySelector("#timeSeed") as HTMLInputElement;

const saudiFlatRate = saudiFlatRateInput?.value ? +saudiFlatRateInput.value : 0;
const nonSaudiFlatRate = nonSaudiFlatRateInput?.value ? +nonSaudiFlatRateInput.value : 0;
const timeSeed = timeSeedInput ? new Date(+timeSeedInput.value) : new Date();

const salaryRangeInput = document.querySelector('#personal-calc__salary') as HTMLInputElement;
const salaryInput = document.querySelector('#personal-calc__salary-value') as HTMLInputElement;

const setSalaryInputWidth = () => {
  salaryInput.style.width = `${salaryRangeInput.value.length * 10 + 15}px`;
};

const renderRangeStyle = (item: HTMLInputElement) => {
  const minValue = +item.min;
  const maxValue = +item.max;
  let percentage = ((+item.value - minValue) / (maxValue - minValue)) * 100;

  if (document.dir == "rtl") {
    item.style.background = `linear-gradient(to left, #18CDB7 0%, #18CDB7 ${percentage}%, #1B82E9 ${percentage}%, #1B82E9 100%)`
  } else {
    item.style.background = `linear-gradient(to right, #18CDB7 0%, #18CDB7 ${percentage}%, #1B82E9 ${percentage}%, #1B82E9 100%)`
  }
}

function initPersonal() {
  if (radios) {
    const minSalarySaudi = document.querySelector(".personal-calc__salary--min-saudi") as HTMLInputElement;
    const minSalaryNonSaudi = document.querySelector(".personal-calc__salary--min-non-saudi") as HTMLInputElement;
    const minSalaryLabel = document.querySelector("#personal-calc__min-value--label") as HTMLSpanElement;

    const ensureRadioValue = (e: Event) => {
      const targetELm = e.target as HTMLInputElement;
      const closestRadio = targetELm.closest('[name="personal-nationality"]:checked') as HTMLInputElement;
      const salaryInput = document.querySelector("#personal-calc__salary-value") as HTMLInputElement;
      const salaryValue = progress[0].value;

      if (closestRadio.value == "Saudi") {
        progress[0].min = minSalarySaudi.value;
        //maxThreshold = +maxThresholdSaudi.value;

        if (+salaryValue < +minSalarySaudi.value) {
          salaryInput.value = formatPrice(minSalarySaudi.value);
          progress[0].value = minSalarySaudi.value;
        }
      } else if (closestRadio.value == "Expat") {
        progress[0].min = minSalaryNonSaudi.value;
        //maxThreshold = +maxThresholdNonSaudi.value;

        if (+salaryValue < +minSalaryNonSaudi.value) {
          salaryInput.value = formatPrice(minSalaryNonSaudi.value);
          progress[0].value = minSalaryNonSaudi.value;
        }
      }

      minSalaryLabel.innerText = formatPrice(progress[0].min);
      // renderRangeStyle(progress[0]);
    }

    Array.from(radios).forEach(input => {
      input.addEventListener("change", e => {
        ensureRadioValue(e);

        setValues(e)
      })
    })
  }

  if (salaryRangeInput) {
    setSalaryInputWidth();
    salaryRangeInput.addEventListener('change', (e) => {
        setSalaryInputWidth();
    });
  }

  if (progress) {
    Array.from(progress).forEach((item) => {
      const value: number = +(item as HTMLInputElement).value;
      const minValue: number = +(item as HTMLInputElement).min;
      const maxValue: number = +(item as HTMLInputElement).max;
      const type = (item as HTMLInputElement).name
      const valueElement = document.querySelector(`.personal-calc__${type}-value`) as HTMLInputElement

      if (type === "tenor") {
        (item as HTMLInputElement).value = Math.ceil((maxValue / 2)).toString()
        valueElement.value = (item as HTMLInputElement).value

      } else {
        (item as HTMLInputElement).value = ((maxValue - minValue) / 2).toString()
        valueElement.value = (item as HTMLInputElement).value
      }

      valueElement.addEventListener("change", e => {
        const inputElement = document.querySelector(`.personal-calc__${type}-input`) as HTMLInputElement
        const parsedValue = parsePrice(valueElement.value)
        inputElement.value = parsedValue
        let percentage = 0
        if (type === "salary") valueElement.value = formatPrice(parsedValue)
        else if (type === "tenor") handleTenureChange(e)

        if (+parsedValue > maxValue) {
          inputElement.value = maxValue.toString()
          valueElement.value = formatPrice(maxValue.toString())
        } else if (+parsedValue < minValue) {
          inputElement.value = minValue.toString()
          valueElement.value = formatPrice(minValue.toString())
        }

        renderRangeStyle(inputElement)

        setValues(e)
      })

      valueElement.dispatchEvent(new Event("change"))

      item.addEventListener('input', e => {
        setInputs(item, e)
      })

      item.dispatchEvent(new Event("input"))
    })
  }
}

function handleTenureChange(elm) {
  if (singularTenureElm && pluralTenureElm) {
    const tenorElm = document.querySelector(".personal-calc__tenor-value-label") as HTMLElement
    tenorElm.innerText = +elm.value === 1 ? singularTenureElm.value : pluralTenureElm.value
  }
}

const formatPrice = (value: string) => parseInt(value.replace(/,/g, "")).toLocaleString("en-US")
const parsePrice = (value: string) => value.replace(/,/g, "")

function setInputs(item, e: any = undefined) {
  const type = (item as HTMLInputElement).name;

  const valueElement = document.querySelector(`.personal-calc__${type}-value`) as HTMLInputElement
  const inputValue = (item as HTMLInputElement).value
  valueElement.value = type === "tenor" ? inputValue : formatPrice(inputValue)
  if (type === "tenor") handleTenureChange(item)

  renderRangeStyle(item)

  setValues(e)
}

function pos_to_neg(num) {
  return -Math.abs(num);
}

var RATE = function (nper, pmt, pv, fv = 0, type = 0, guess = 0.1) {
  const epsMax = 1e-10;
  const maxIterations = 1000;

  let rate = guess;
  for (let i = 0; i < maxIterations; i++) {
    const f = Math.pow(1 + rate, nper);
    const y = pv * f + pmt * (1 + rate * type) * (f - 1) / rate + fv;

    if (Math.abs(y) < epsMax) {
      break;
    }
    const dy = nper * pv * Math.pow(1 + rate, nper - 1)
      + pmt * type * (f - 1) / (rate * rate)
      + pmt * (1 + rate * type)
      * (nper * Math.pow(1 + rate, nper - 1) / rate - (f - 1) / (rate * rate));

    rate -= y / dy;
  }

  return rate;
};

function calculateMaxSalaryThreshold(g8, Term, maxThreshold = 1600000, maxTotalMonthlyIncome = 1000000, minTotalMonthlyIncome = 22000) {
  for (let i = maxThreshold; i >= minTotalMonthlyIncome; i--) {
    let g6 = i >= 25000 ? 0.75 : 0.45;
    let g9 = i * g6;
    let g7 = Term;
    let g10 = g9 * g7;
    let g11 = g10 / (1 + (((g7 * g8) / 100) / 12));
    if (g11 < maxThreshold) return i;
  }
  return minTotalMonthlyIncome;
}

function calculateMaxTermThreshold(g8, totalMonthlyIncome, maxThreshold = 1600000, maxTermAmount = 60, minTermAmount = 6) {
  let g6 = totalMonthlyIncome >= 25000 ? 0.75 : 0.45;
  for (let i = maxTermAmount; i >= minTermAmount; i--) {
    let g9 = totalMonthlyIncome * g6;
    let g10 = g9 * i;
    let g11 = g10 / (1 + (((i * g8) / 100) / 12));
    if (g11 <= maxThreshold) return i;
  }
  return minTermAmount;
}

function setValues(e: any = undefined) {
  const TermElement = document.getElementById("personal-calc__tenor") as HTMLInputElement
  let Term = +TermElement.value
  const TotalIncomeElement = document.getElementById("personal-calc__salary") as HTMLInputElement
  const nationalityElement = document.querySelector('input[name="personal-nationality"]:checked') as HTMLInputElement
  const maxThresholdSaudi = personalCalc.querySelector("#maxThresholdSaudi") as HTMLInputElement;
  const maxThresholdNonSaudi = personalCalc.querySelector("#maxThresholdNonSaudi") as HTMLInputElement;
  const Nationality = nationalityElement.value
  let TotalMonthlyIncome = +TotalIncomeElement.value;
  let threshold = 1600000;

  let g8;
  if (Nationality == "Saudi") {
    if (maxThresholdSaudi?.value) threshold = +maxThresholdSaudi.value;
    g8 = saudiFlatRate;
  } else {
    if (maxThresholdNonSaudi?.value) threshold = +maxThresholdNonSaudi.value;
    g8 = nonSaudiFlatRate;
  }
  g8 /= 100;

  const principalAmount = calculatePrincipalAmount(TotalMonthlyIncome, Term, g8, threshold);
  let g6 = principalAmount;
  let g7 = Term;

  //monthly installment
  let g14 = ((g6 * g8 * g7 / 12) + g6) / g7;

  //total loan amount - Total Repayment Amount
  let g15 = g14 * g7;

  // managment fees
  managementFee = principalAmount * 0.01;
  if (managementFee > 5000) {
    managementFee = 5000;
  }
  var managementFee_VAT = Math.min(managementFee * 0.15, 750);
  managementFee += managementFee_VAT;
  const apr = calculateLoanDetails(principalAmount, Term, g8, managementFee, new Date(timeSeed)).apr;

  const output1 = document.querySelector(".personal-calc__payment-number") as HTMLElement
  const output2 = document.querySelector(".personal-calc__payment-rate-amount") as HTMLInputElement
  const output3 = document.querySelector(".personal-calc__payment-max-amount") as HTMLInputElement

  // monthly installment
  output1.textContent = g14.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // percente rate
  output2.textContent = formatNumber(apr)
  //max finance amount
  output3.textContent = formatNumber(principalAmount)
}

// --------OLD CALCULATION ALGO---------
// function setValues(e: any = undefined) {
//   const TermElement = document.getElementById("personal-calc__tenor") as HTMLInputElement
//   let Term = +TermElement.value
//   const nationalityElement = document.querySelector('input[name="personal-nationality"]:checked') as HTMLInputElement
//   const Nationality = nationalityElement.value
//   const TotalIncomeElement = document.getElementById("personal-calc__salary") as HTMLInputElement
//   let TotalMonthlyIncome = +TotalIncomeElement.value


//   let flatRateSaudi = +(document.getElementById("personal-calc--flat-rate-saudi") as HTMLInputElement).value;
//   if (flatRateSaudi === null) {
//     flatRateSaudi = 12;
//   }
//   let flatRateNonSaudi = +(document.getElementById("personal-calc--flat-rate-non-saudi") as HTMLInputElement).value;
//   if (flatRateNonSaudi === null) {
//     flatRateNonSaudi = 13;
//   }

//   let g8 = flatRateSaudi;
//   if (Nationality != "Saudi") {
//     g8 = flatRateNonSaudi;
//   }

//   let g6 = 0.45;
//   if (TotalMonthlyIncome >= 25000) {
//     g6 = 0.75;
//   }

//   let g7 = +Term;
//   let g9 = TotalMonthlyIncome * g6;
//   let g12 = g9 * g7;
//   let g13 = Math.min(maxThreshold, g12 / (1 + (((g7 * g8) / 100) / 12)));
//   let g10 = (g13 * (1 + (g8 * (g7 / 12)) / 100)) / g7;
//   let g11 = g10 * g7;
//   let g14 = g12 - g13;

//   let g15 = g12 * 0.01;
//   if (g15 > 5000) {
//     g15 = 5000;
//   }
//   let g16 = g15 * 0.15;
//   if (g16 > 750) {
//     g16 = 750;
//   }
//   let g17 = g15 + g16;
//   let g18 = g13 - g17;

//   let k6 = RATE(g7, pos_to_neg(g10), g18);

//   let k7 = (((1 + k6) ** 12) - 1);
//   k7 *= 100;

//   const output1 = document.querySelector(".personal-calc__payment-number") as HTMLElement
//   const output2 = document.querySelector(".personal-calc__payment-rate-amount") as HTMLInputElement
//   const output3 = document.querySelector(".personal-calc__payment-max-amount") as HTMLInputElement

//   // monthly installment
//   output1.textContent = g10.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   // percente rate
//   output2.textContent = formatNumber(k7)
//   //max finance amount
//   output3.textContent = formatNumber(g13)
// }

function formatNumber(num: number) {
  if (num < 0) num *= -1;

  if (+num % 1 !== 0) {
    return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

// function showPersonalError() {
//   const errorElement = document.querySelector(".personal-calc__error-wrapper") as HTMLElement
//   errorElement.classList.add("personal-calc__error-wrapper--active")
// }

// function removePersonalError() {
//   const errorElement = document.querySelector(".personal-calc__error-wrapper") as HTMLElement
//   errorElement.classList.remove("personal-calc__error-wrapper--active")
// }

initPersonal();
